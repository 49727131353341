/* eslint-disable */
import { dove } from './images'

export const theLightTheme = {
    type: 'light',
    primary: '#cd50d9',
    quaternary: '#f4e1fc',
    quaternaryLight: '#e38aeb',
    secondary: '#ffffff',
    tertiary: '#4e7ccc',
    fifth: '#ffffff',
    sixth: '#0f1419',
    buttonColor: '#0F1419',
    contactsimg: dove
}

export const theDarkTheme = {
    type: 'dark',
    primary: '#cd50d9',
    secondary: '#1a70d9',
    quaternary: '#f5b7f7',
    quaternaryLight: '#084457',
    tertiary: '#8ceffa',
    fifth: '#ffffff',
    sixth: '#0f1419',
    buttonColor: '#8B98A5',
    contactsimg: dove
}


import React from 'react';
import Typed from 'react-typed';
import { headerData } from '../../../data/headerData';
import './landing.css';
import { useTranslation } from '../../../TranslationContext';

function LandingUI({ theme, drawerOpen, classes }) {
    const { translate } = useTranslation();

    return (
        <div className='landing' style={{ backgroundColor: theme.quaternary }}>
            <div className='landing--container'>
                <div
                    className='landing--container-left'
                    style={{ backgroundColor: theme.quaternary }}
                >
                    
                </div>
                <img
                    src={headerData.image}
                    alt=''
                    className='landing--img'
                    style={{
                        opacity: `${drawerOpen ? '0' : '1'}`,
                        borderColor: theme.primary,
                    }}
                />
                <div
                    className='landing--container-right'
                    style={{ backgroundColor: theme.secondary }}
                >
                    <div
                        className='lcr--content'
                        style={{ color: theme.tertiary }}
                    >
                        {/* <h6 style={{ color: theme.primary }}>{headerData.title}</h6> */}
                        <h1>{translate('title', 'landing')}</h1>
                        <Typed
                            strings={[
                                translate('typed1', 'landing'),
                                translate('typed2', 'landing'),
                                translate('typed3', 'landing'),
                            ]}
                            typeSpeed={50}
                            backSpeed={50}
                            className="typed-header"
                            style={{ color: theme.primary, fontSize: '28px' }}
                            loop
                        />
                        <p>{translate('description', 'landing')}</p>
                                  
                        </div>
                    </div>
                </div>
            </div>
    
    );
}

export default LandingUI;

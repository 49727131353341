import React from 'react';
import { useTranslation } from './TranslationContext';

const LanguageSwitcher = () => {
  const { changeLanguage, currentLanguage } = useTranslation();

  const handleLanguageChange = () => {
    const newLanguage = currentLanguage === 'en' ? 'sp' : 'en';
    changeLanguage(newLanguage);
  };

  return (
    <button
      onClick={handleLanguageChange}
      style={{
        margin: '1px',
        border: '3px solid #f75cee',
        backgroundColor: '#dba9d8',
        color: 'white',
        fontSize: '18px',
        fontFamily: 'Delius Swash Caps',
        fontWeight: '400',
        padding: '5px',
        marginTop: '5px',
        marginBottom: '5px',
        marginLeft: '5px',
        textShadow: '0px 1px 1px rgb(11, 11, 11), 0 0 0.8em white, 0 0 0.1em rgb(18, 17, 17)',
        boxShadow: '0 0 12px 2px #f75cee',
        borderRadius: '30px',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
      }}

    >
      {currentLanguage === 'en' ? 'Spanish' : 'English'}
    </button>
  );
};

export default LanguageSwitcher;



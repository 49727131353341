import React, { createContext, useContext, useState } from 'react';

const TranslationContext = createContext();

const translations = {
  en: {
    navbar: {
      title: 'Your name',
      menu: 'Menu',
      home: 'Home',
      about: 'About me',
      projects: 'My links',
      themeSwitch: 'Light/Dark',
    },
    landing: {
      title: 'Hello 💋',
      description: "The description of your choice to awaken your audience, just a few sentences (you could describe yourself, more in 'about me' section), so 2 sentences is enough. Example : Welcome to my personal page, etc...",
      contactBtn: 'Contact',
      typed1: 'Your name',
      typed2: 'Text of your choice 1',
      typed3: 'Text of your choice 2',
    },
    about: {
    title: 'About me:',
      description1: 'I am a woman who loves, etc...',
      description2: " Description 1",
      description3: 'Description 2',
      description4: 'Description 3',
      description5: 'Description 4',
      description6: 'Description 5',
      description7: "I want to accomplish all your fantasies !",
    },
    projects: {
      title: 'My links',
    },
   
    },

  sp: {
    navbar: {
      title: 'Su nombre',
      menu: 'Menu',
      home: 'Accueil',
      about: 'Acerca de mí',
      projects: 'Mis links',
      themeSwitch: 'Oscura/Luz',
    },
    landing: {
      title: 'Hola 💋',
      description: "La descripción de tu elección para despertar a tu audiencia, solo unas pocas oraciones (podrías describirte más en 'acerca de mí'), por lo que 2 oraciones son suficientes. Ejemplo : Bienvenido am mi pagina de prensentacion personal, etc...",
      contactBtn: 'Contactar',
      typed1: "Su nombre",
      typed2: 'Texto de tu elección 1',
      typed3: 'Texto de tu elección 2',
    },
    about: {
      title: 'Acerca de mí:',
      description1: 'Soy una mujer que ama, etc...',
      description2: "Descripción 1",
      description3: 'Descripción 2',
      description4: 'Descripción 3',
      description5: 'Descripción 4',
      description6: 'Descripción 5',
      description7: "Quiero cumplir todas tus fantasías !",
    },
    projects: {
      title: 'Mis Links',
    },
   
    },  
};

const TranslationProvider = ({ children }) => {
  const [language, setLanguage] = useState('en');

  const translate = (key, section) => {
    return translations[language] && translations[language][section] && translations[language][section][key]
      ? translations[language][section][key]
      : key;
  };

  const changeLanguage = (newLanguage) => {
    setLanguage(newLanguage);
  };

  return (
    <TranslationContext.Provider value={{ translate, changeLanguage, currentLanguage: language }}>
      {children}
    </TranslationContext.Provider>
  );
};

const useTranslation = () => {
  return useContext(TranslationContext);
};

export { TranslationProvider, useTranslation };



import love from '../assets/png/love.png';
import heartLock from '../assets/png/heart-lock.png';

export const projectsData = [
    {
        id: 1,
        projectName:'OnlyFans',
        tags: ['Service 1', 'Service 2', 'Service 3', 'Service 4', 'Service 5'],
        demo: 'https://onlyfans.com/',
        image: love, href: 'https://onlyfans.com/',
    },
    {
        id: 2,
        projectName: 'Fansly',
        tags: ['Service 1', 'Service 2', 'Service 3', 'Service 4', 'Service 5'],
        demo: 'https://fansly.com/',
        image: heartLock, href: 'https://fansly.com/',
    },
  
];
